import { useEffect } from 'react';
import { useOry } from '@noah-labs/fe-shared-data-access-auth';
import { disableRefetchRetry } from '@noah-labs/fe-shared-data-access-shared';
import { useAuthError } from '@noah-labs/fe-shared-ui-auth';
import type { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { compareStrings, duration, withTimeout } from '@noah-labs/shared-util-vanilla';
import type { Session } from '@ory/client';
import axios from 'axios';
import { useQuery } from 'react-query';

/**
 * Add some typeguard helper functions
 */
type TpAuthUserTraits = {
  email: string;
};
function isTpAuthUserTraits(traits: unknown): traits is TpAuthUserTraits {
  if (!traits || typeof traits !== 'object') {
    return false;
  }
  const { email } = traits as TpAuthUserTraits;
  if (!email) {
    return false;
  }
  return true;
}

type TpAuthUserPublicMetadata = {
  referralCode?: string;
  userId: string;
};
function isTpAuthUserPublicMetadata(
  publicMetadata: unknown,
): publicMetadata is TpAuthUserPublicMetadata {
  if (!publicMetadata || typeof publicMetadata !== 'object') {
    return false;
  }
  const { userId } = publicMetadata as TpAuthUserPublicMetadata;
  if (!userId) {
    return false;
  }
  return true;
}

function hasVerifiedEmail(email: string | undefined, session: Session | undefined): boolean {
  if (!email) {
    return false;
  }
  return Boolean(
    session?.identity?.verifiable_addresses?.find(
      (address) => address.verified && compareStrings(address.value, email),
    ),
  );
}

function getAuthStatus(
  isFetched: boolean,
  verified: boolean,
  session: Session | undefined,
): TpAuthStatus {
  if (!isFetched) {
    return 'unknown';
  }
  if (!session?.identity) {
    return 'guest';
  }
  if (!verified) {
    return 'authenticatedNotVerified';
  }
  return 'authenticated';
}

/**
 * Setup signoutSubscribers and onSignOut callback
 */
type TpFunc = (() => void) | (() => Promise<void>);

const signoutSubscribers = new Map<string, TpFunc>();
function addSignOutSubscriber(name: string, sub: TpFunc): void {
  signoutSubscribers.set(name, sub);
}

async function onSignOut(): Promise<void> {
  try {
    logger.debug('calling subscribers');
    const promises: Array<ReturnType<TpFunc>> = [];
    signoutSubscribers.forEach((cb) => {
      // wrap the callbacks in a raced promise of 1s to ensure they resolve and not block signout
      promises.push(withTimeout(cb(), duration.seconds(1)));
    });

    await Promise.allSettled(promises);
    signoutSubscribers.clear();
  } catch (error) {
    // we wouldn't really want this to block the logout
    logger.error(error);
  }
}

/**
 * Main useAuth function
 */
type TpUseAuthData = {
  email: string | undefined;
  referralCode: string | undefined;
  sessionId: string | undefined;
  userId: string | undefined;
  verified: boolean;
};

export type TpUseAuth = {
  AuthErrorScene: React.ReactElement | null;
  addSignOutSubscriber: (name: string, sub: TpFunc) => void;
  authStatus: TpAuthStatus;
  data: TpUseAuthData | undefined;
  isFetched: boolean;
  isFetching: boolean;
  onSignOut: () => Promise<void>;
};

export const orySessionKey = ['ory/session'];

let hasSession: boolean;
export function useAuth(): TpUseAuth {
  const { ory } = useOry();

  const {
    data: session,
    error,
    isFetched,
    isFetching,
  } = useQuery(
    orySessionKey,
    async () => {
      try {
        const orySession = await ory.toSession();
        logger.debug('orySession:', orySession.data);

        hasSession = true;

        return orySession.data;
      } catch (err) {
        /**
         * User is not authenticated - call onSignOut
         */
        await onSignOut();

        /**
         * If the error is a 401 it just means the user is not logged in, we can continue
         */
        if (!axios.isAxiosError(err) || err.response?.status !== 401) {
          throw err;
        }

        // Redirect to SignedOut scene if user has an expired session
        if (hasSession) {
          hasSession = false;
          window.location.assign(authRoutes.signedOut.path);
        }

        return undefined;
      }
    },
    {
      ...disableRefetchRetry,
      // unauthed queries are off by default, explicitly enable Ory query
      enabled: true,
      refetchInterval: duration.minutes(5),
    },
  );

  /**
   * Check if email address is verified
   */
  const { metadata_public: mp, traits } = session?.identity || {};
  const email = isTpAuthUserTraits(traits) ? traits.email : undefined;
  const verified = hasVerifiedEmail(email, session);

  /**
   * Set the Auth Status
   */
  const authStatus = getAuthStatus(isFetched, verified, session);

  /**
   * Check for Auth Errors
   */
  const { AuthErrorScene } = useAuthError({ error });

  const baseAuth = {
    addSignOutSubscriber,
    AuthErrorScene,
    authStatus,
    isFetched,
    isFetching,
    onSignOut,
  };
  let auth: TpUseAuth;
  switch (authStatus) {
    case 'unknown':
      auth = {
        ...baseAuth,
        data: undefined,
      };
      break;

    case 'guest':
      auth = {
        ...baseAuth,
        data: {
          email: undefined,
          referralCode: undefined,
          sessionId: undefined,
          userId: undefined,
          verified: false,
        },
      };
      break;

    case 'authenticatedNotVerified':
    case 'authenticated':
    default: {
      const { referralCode, userId } = isTpAuthUserPublicMetadata(mp)
        ? mp
        : { referralCode: undefined, userId: undefined };

      auth = {
        ...baseAuth,
        data: {
          email,
          referralCode,
          sessionId: session?.id,
          userId,
          verified,
        },
      };
      break;
    }
  }

  useEffect(() => {
    logger.debug('auth:', auth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus]);

  return auth;
}
