import { Fragment } from 'react';
import type { LnurlError as TpLnurlError } from '@noah-labs/shared-schema-gql';
import { GetInTouchLink } from '../buttons/GetInTouchLink';
import type { TpPushAlert } from './usePushAlert';

/**
 * Errors
 */
export function LnurlErrorAlert({ Domain, Reason }: TpLnurlError): TpPushAlert {
  return {
    autoHideDuration: 6000,
    dismissable: true,
    key: 'LnurlError',
    message: `The ${Domain} lightning service responded with: ${Reason}`,
    preventDuplicate: true,
    severity: 'error',
  };
}

export function AlertError(key: string, error: React.ReactNode): TpPushAlert {
  return {
    autoHideDuration: 3000,
    key,
    message: error,
    preventDuplicate: true,
    severity: 'error',
  };
}

export const PaymentCardDeleteErrorAlert: TpPushAlert = {
  key: 'deletePaymentCardError',
  message: (
    <Fragment>
      Sorry, we had trouble deleting your card, please try again later. If the problem persists,
      please <GetInTouchLink color="inherit" underline="always" />.
    </Fragment>
  ),
  preventDuplicate: true,
  severity: 'error',
};

export const PaymentCardUpdateErrorAlert: TpPushAlert = {
  key: 'fiatPaymentUpdateError',
  message: (
    <Fragment>
      Sorry, we had trouble updating your card, please try again later. If the problem persists,
      please <GetInTouchLink color="inherit" underline="always" />.
    </Fragment>
  ),
  preventDuplicate: true,
  severity: 'error',
};

export const UnsupportedPayoutCardAlert: TpPushAlert = {
  autoHideDuration: 6000,
  dismissable: true,
  key: 'unsupportedPayoutCard',
  message: 'This card is not supported for sell, please try a different card.',
  preventDuplicate: true,
  severity: 'warning',
};

export const AddressCopiedAlert: TpPushAlert = {
  autoHideDuration: 2000,
  dismissable: true,
  key: 'addressCopied',
  message: 'Address copied to clipboard.',
  preventDuplicate: true,
  severity: 'success',
};

export const InvalidAddressErrorAlert: TpPushAlert = {
  autoHideDuration: 6000,
  dismissable: true,
  key: 'invalidAddress',
  message:
    'Oops! The address you entered is invalid. Please double-check for any errors and try again.',
  preventDuplicate: true,
  severity: 'error',
};
/**
 * Errors
 */
export const ApiUnknown: TpPushAlert = AlertError(
  'unknown',
  <Fragment>
    Sorry, something went wrong. Please try again and if the problem persists, please{' '}
    <GetInTouchLink color="inherit" underline="always" />.
  </Fragment>,
);

export const AccountNameCopiedAlert: TpPushAlert = {
  autoHideDuration: 2000,
  dismissable: true,
  key: 'accountNameCopied',
  message: 'Account name copied to clipboard.',
  preventDuplicate: true,
  severity: 'success',
};

export const AccountNumberCopiedAlert: TpPushAlert = {
  autoHideDuration: 2000,
  dismissable: true,
  key: 'accountNumberCopied',
  message: 'Account number copied to clipboard.',
  preventDuplicate: true,
  severity: 'success',
};
