import { css } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import * as GTAMExpanded from '@noah-labs/fe-feature-assets/fonts/gt-america-expanded';
import * as GTAMExtended from '@noah-labs/fe-feature-assets/fonts/gt-america-extended';
import * as GTAMStandard from '@noah-labs/fe-feature-assets/fonts/gt-america-standard';
import { coreThemeProps, cssMixins } from '@noah-labs/fe-shared-ui-components';
import { getWebComponents } from './components';

const lightThemeBase = createTheme(
  deepmerge(coreThemeProps, {
    themeName: 'Web',
  }),
);

export const lightTheme = createTheme(lightThemeBase, {
  components: getWebComponents(lightThemeBase),
});

export const globalStyles = css`
  ${cssMixins.global};
  ${GTAMStandard};
  ${GTAMExpanded};
  ${GTAMExtended};
`;
